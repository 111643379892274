import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga";

class Footer extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.id = "mainjs";
    minscript.src = publicUrl + "assets/js/main.js";

    if(document.getElementById(minscript.id)){
      document.getElementById(minscript.id).remove();
    }

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="footer-area">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-sm-4">
                <Link className="footer-logo" to="/">
                  <img src={publicUrl + "assets/img/logo.png"} alt={imgattr} />
                </Link>
              </div>
              <div className="col-sm-8">
                <div className="footer-social text-sm-right">
                  <span>{this.props.t("footer.socialtitle")}</span>
                  <ul className="social-icon">
                    <li>
                      <ReactGA.OutboundLink
                        eventLabel="Footer Facebook Button"
                        to="https://www.facebook.com/people/TLG-Construction/100083452922369"
                        target="_blank"
                        className="facebook"
                      >
                        <FontAwesomeIcon icon={["fab", "facebook"]} />
                      </ReactGA.OutboundLink>
                    </li>
                    <li>
                      <ReactGA.OutboundLink
                        eventLabel="Footer Instagram Button"
                        to="https://www.instagram.com/tlgconstruction_"
                        target="_blank"
                        className="instagram"
                      >
                        <FontAwesomeIcon icon={["fab", "instagram"]} />
                      </ReactGA.OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="copy-right text-center"
            dangerouslySetInnerHTML={{
              __html: this.props.t("footer.copyrighttext"),
            }}
          ></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
