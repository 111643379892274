import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isNavCollapsed: true };
  }
  // const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  componentDidMount() {
    const languageSelect = document.querySelector("#page_language");
    languageSelect.value = this.props.pageLanguage;
  }

  handleNavCollapse = () => {
    if (window.innerWidth < 992) {
      this.setState({ isNavCollapsed: !this.state.isNavCollapsed });
      this.setMainMenuMaxHeight();
    }
  };

  setMainMenuMaxHeight = () => {
    const mainMenu = document.getElementById("tg-homes-main-menu");
    if (mainMenu.style.maxHeight) {
      mainMenu.style.maxHeight = null;
    } else {
      mainMenu.style.maxHeight = mainMenu.scrollHeight + "px";
    }
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <nav className="navbar navbar-area navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              className={`menu toggle-btn d-block d-lg-none ${
                this.state.isNavCollapsed ? " " : "open"
              }`}
              data-toggle="collapse"
              data-target="#tg-homes-main-menu"
              aria-expanded={!this.state.isNavCollapsed ? true : false}
              aria-label="Toggle navigation"
              onClick={this.handleNavCollapse}
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo readeal-top">
            <Link to="/">
              <img src={publicUrl + "assets/img/logo.png"} alt="logo" />
            </Link>
          </div>
          <div
            className={` navbar-collapse ${
              !this.state.isNavCollapsed ? "menu-open" : ""
            }`}
            id="tg-homes-main-menu"
          >
            <ul className="navbar-nav readeal-top">
              <li>
                <Link to="/" onClick={this.handleNavCollapse}>
                  {this.props.t("navbar.home")}
                </Link>
              </li>
              <li>
                <Link to="/about-us" onClick={this.handleNavCollapse}>
                  {this.props.t("navbar.about")}
                </Link>
              </li>
              <li>
                <Link to="/property-list" onClick={this.handleNavCollapse}>
                  {this.props.t("navbar.propertylist")}
                </Link>
              </li>
              <li>
                <Link to="/contact-us" onClick={this.handleNavCollapse}>
                  {this.props.t("navbar.contactus")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="lang-select">
            <select
              id="page_language"
              name="page_language"
              onChange={this.props.onLanguageChange}
            >
              <option value="en">English</option>
              <option value="tr">Türkçe</option>
            </select>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
