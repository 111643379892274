import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SwiperSlider from "../utilities/SwiperSlider";
import { Pagination } from "swiper/modules";
import SubscribeForm from "../utilities/SubscribeForm";

const sliderOptions = {
  className: "property-details-slider",
  slidesPerView: 1,
  loop: true,
  loopfillgroupwithblank: "true",
  spaceBetween: 10,
  pagination: {
    clickable: true,
  },
  modules: [Pagination],
};

class PropertyDetailsContent extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      window.location.reload(true);
    }
  }

  render() {
    let PropertyFeatures,
      PropertyDescription,
      Property3DGallery,
      PropertyLocation;
    if (this.props.features) {
      PropertyFeatures = this.props.features.map((item, i) => (
        <div key={i} className="col-md-3 col-sm-6 mg-bottom-30">
          <div className="single-property-info">
            <h5>{item.title}</h5>
            <p>
              <FontAwesomeIcon icon={item.icon} /> {item.value}
            </p>
          </div>
        </div>
      ));
    }
    if (this.props.description) {
      PropertyDescription = (
        <div className="property-details-single-card style-two border-bottom-color-one">
          <h4 style={{marginBottom:"45px"}}>{this.props.t("propertydetails.description")}</h4>
          <div>{
          this.props.description.map((element, index)=>{
            return(<div key={index} style={{marginBottom:"45px"}}>
              <h5>{element.title}</h5>
              <p>{element.text}</p>
            </div>)
          })
          }</div>
        </div>
      );
    }
    if (this.props.threedgallery) {
      Property3DGallery = (
        <div className="property-details-single-card border-bottom-color-one mb-0">
          <h4>{this.props.t("propertydetails.threedgallery")}</h4>
          <h6>{this.props.threedsize}</h6>
          <iframe
            src={this.props.threedgallery}
            title="3D Gallery"
            allowFullScreen={true}
            allow="gyroscope; accelerometer; xr-spatial-tracking; vr;"
          />
        </div>
      );
    }
    if (this.props.location) {
      PropertyLocation = (
        <div className="property-details-single-card style-two border-bottom-color-one pd-top-60">
          <h4>{this.props.t("propertydetails.location")}</h4>
          <iframe
            src={this.props.location}
            title="Property Location"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      );
    }

    return (
      <div className="property-details-area">
        <div className="property-details-container bg-color-one pd-top-100 pd-bottom-90">
          <div className="container">
            {this.props.soldout ? (
              <div className="row ">
                <div className="col-12">
                  <div className="row">
                    <SwiperSlider
                      sliderImageList={this.props.slider}
                      sliderOptions={sliderOptions}
                    />
                  </div>
                  <div className="property-details-slider-info">
                    <h3>
                      <span>{this.props.newprice}</span> {this.props.title}
                    </h3>
                    <del>{this.props.oldprice}</del>
                    {this.props.soldout ? (
                      <span className="sold_out">
                        {this.props.t("common.soldout")}
                      </span>
                    ) : (
                      <span className="on_sale">
                        {this.props.t("common.onsale")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row ">
                <div className="col-xl-9 col-lg-8">
                  <div className="row">
                    <SwiperSlider
                      sliderImageList={this.props.slider}
                      sliderOptions={sliderOptions}
                      style={{ textAlign: "center" }}
                    />
                  </div>
                  <div className="property-details-slider-info">
                    <h3>
                      <span>{this.props.newprice}</span> {this.props.title}
                    </h3>
                    <del>{this.props.oldprice}</del>
                    {this.props.soldout ? (
                      <span className="sold_out">
                        {this.props.t("common.soldout")}
                      </span>
                    ) : (
                      <span className="on_sale">
                        {this.props.t("common.onsale")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                  <SubscribeForm
                    propertyName={this.props.title}
                    t={this.props.t}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row pd-top-90">
            {this.props.soldout ? (
              <div className="col-12">
                <div className="property-info mb-5">
                  <div className="row">{PropertyFeatures}</div>
                </div>
                {PropertyDescription}
                {Property3DGallery}
                {PropertyLocation}
              </div>
            ) : (
              <div className="col-xl-9">
                <div className="property-info mb-5">
                  <div className="row">{PropertyFeatures}</div>
                </div>
                {PropertyDescription}
                {Property3DGallery}
                {PropertyLocation}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyDetailsContent;
