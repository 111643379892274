import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const inlineStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/img/banner/banner_breadcrumb.jpg)"
    };
    return (
      <div className="breadcrumb-area" style={inlineStyle}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner">
                <h1 className="page-title">
                  {this.props.t(`navbar.${this.props.headertitle}`)}
                </h1>
                <ul className="page-list">
                  <li>
                    <Link to="/">{this.props.t("navbar.home")}</Link>
                  </li>
                  <li>{this.props.t(`navbar.${this.props.headertitle}`)}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
