import React from "react";
import PageHeader from "./global-components/Header";
import AboutUsContent from "./section-components/AboutUsContent";
import Services from "./section-components/Services";
import Footer from "./global-components/Footer";

const AboutUs = (props) => {
  return (
    <div>
      <PageHeader headertitle="about" t={props.t} />
      <AboutUsContent t={props.t} />
      <Services t={props.t} />
      <Footer t={props.t} />
    </div>
  );
};

export default AboutUs;
