import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SwiperSlider = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const sliderImages = [];
  const getSwiperSlide = () => {
      if(props.sliderItems){
        const sliderItems =
        props.sliderItems.map((item, i) => (
          <SwiperSlide key={i}>{item}</SwiperSlide>
        ))
        return sliderItems
      }
      
      if(props.sliderImageList && props.sliderImageList.length !== 0){
        for (const [i, value] of props.sliderImageList.entries()) {
          sliderImages.push(
            <SwiperSlide key={i}><img src={publicUrl + value} alt={imagealt} /></SwiperSlide>
          );
      }
      return sliderImages
    }
  }

  return (
    <Swiper {...props.sliderOptions}>
      {getSwiperSlide()}
    </Swiper>
  );
};

export default SwiperSlider;