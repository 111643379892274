import React, { Component } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnalyticsEventTracker from "../utilities/AnalyticsEventTracker";

class PropertyContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const gaEventTracker = AnalyticsEventTracker("Property List");
    const propertyItems = this.props.items.slice(0).reverse();
    const propertyImages = this.props.propertiesImages.items.slice(0).reverse();

    return (
      <div className="property-area pd-top-100 pd-bottom-70">
        <div className="container">
          <div className="property-filter-area row custom-gutter">
            <div className="gallery-sizer col-1" />
            {propertyItems.map((item, i) => (
              <div
                key={i}
                className={"rld-filter-item col-sm-6 col-lg-4 col-xl-3 "}
              >
                <div className="single-feature">
                  <div className="thumb">
                    <Link
                      to={`${item.url}${item.id}`}
                      onClick={gaEventTracker.bind(
                        this,
                        "Property Image",
                        item.title
                      )}
                    >
                      <img
                        src={publicUrl + propertyImages[i].image}
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="details">
                    {item.soldout ? (
                      <span className="sold_out">
                        {this.props.t("common.soldout")}
                      </span>
                    ) : (
                      <span className="on_sale">
                        {this.props.t("common.onsale")}
                      </span>
                    )}
                    <div className="feature-logo">
                      <img
                        src={publicUrl + propertyImages[i].icon}
                        alt={imagealt}
                      />
                    </div>
                    <h6 className="title readeal-top">
                      <Link
                        to={`${item.url}${item.id}`}
                        onClick={gaEventTracker.bind(
                          this,
                          "Property Title",
                          item.title
                        )}
                      >
                        {item.title}
                      </Link>
                    </h6>
                    <h6 className="price">{item.newprice}</h6>
                    <del>{item.oldprice}</del>
                    <ul className="info-list">
                      {item.features.map((features, i) => (
                        <li key={i}>
                          <FontAwesomeIcon icon={features.icon} />{" "}
                          {features.value}
                        </li>
                      ))}
                    </ul>
                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+905338848853"
                          onClick={gaEventTracker.bind(
                            this,
                            "Call",
                            item.title
                          )}
                        >
                          <FontAwesomeIcon icon={"phone"} />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:tghomes22@gmail.com"
                          onClick={gaEventTracker.bind(
                            this,
                            "Mail",
                            item.title
                          )}
                        >
                          <FontAwesomeIcon icon={"envelope"} />
                        </a>
                      </li>
                      <li className="readeal-top">
                        <Link
                          className="btn btn-main-color-one"
                          to={`${item.url}${item.id}`}
                          onClick={gaEventTracker.bind(
                            this,
                            "View Details",
                            item.title
                          )}
                        >
                          {this.props.t("common.btnviewdetails")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyContent;
