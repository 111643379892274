import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class WhyChooseUs extends Component {
  render() {
    return (
      <div className="why-choose-us-area bg-color-one pd-top-90 pd-bottom-60">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">{this.props.t("whychooseus.title")}</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4 mg-bottom-30">
              <div className="single-intro text-center">
                <div className="thumb">
                  <FontAwesomeIcon icon={"file-invoice-dollar"} />
                </div>
                <div className="details">
                  <h4 className="title">
                    {this.props.t("whychooseus.items.title1")}
                  </h4>
                  <p>{this.props.t("whychooseus.items.content1")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mg-bottom-30">
              <div className="single-intro text-center">
                <div className="thumb">
                  <FontAwesomeIcon icon={"pen-ruler"} />
                </div>
                <div className="details">
                  <h4 className="title">
                    {this.props.t("whychooseus.items.title2")}
                  </h4>
                  <p>{this.props.t("whychooseus.items.content2")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mg-bottom-30">
              <div className="single-intro text-center">
                <div className="thumb">
                  <FontAwesomeIcon icon={"city"} />
                </div>
                <div className="details">
                  <h4 className="title">
                    {this.props.t("whychooseus.items.title3")}
                  </h4>
                  <p>{this.props.t("whychooseus.items.content3")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyChooseUs;
