import React from "react";
import { useForm } from "react-hook-form";
import { Report } from "notiflix/build/notiflix-report-aio";

function ContactForm(props) {
  const {
    register,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValue: { full_name: "" } });

  const onSubmit = (data) => onPostForm(data);

  async function onPostForm(data) {
    try {
      const response = await fetch("/api/contact_us", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log(result);
      Report.success(
        `${props.t("form.reports.success.title")}`,
        `${props.t("form.reports.success.message")}`,
        `${props.t("common.btnokey")}`
      );
      reset();
    } catch (err) {
      Report.failure(
        `${props.t("form.reports.failed.title")}`,
        `${props.t("form.reports.failed.message")}`,
        `${props.t("common.btnokey")}`
      );
      console.log(err);
    }
  }

  return (
    <form
      className="contact-form-wrap contact-form-bg"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="rld-single-input">
        <input
          {...register("full_name", {
            required: true,
          })}
          placeholder={props.t("contactus.name")}
          autoComplete="off"
        />
        <span className="form-error">
          {errors.full_name?.type === "required" &&
            `${props.t("form.errors.fullname")}`}
        </span>
      </div>

      <div className="rld-single-input">
        <input
          {...register("email", {
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: true,
            },
          })}
          placeholder={props.t("contactus.email")}
          autoComplete="off"
        />
        <span className="form-error">
          {(errors.email?.type === "required" &&
            `${props.t("form.errors.email")}`) ||
            (errors.email?.message && `${props.t("form.errors.email_format")}`)}
        </span>
      </div>

      <div className="rld-single-input">
        <input
          {...register("phone", { required: true })}
          placeholder={props.t("contactus.phone")}
          autoComplete="off"
        />
        <span className="form-error">
          {errors.phone && `${props.t("form.errors.phone")}`}
        </span>
      </div>

      <div className="rld-single-input">
        <textarea
          rows={10}
          placeholder={props.t("contactus.message")}
          defaultValue={""}
          {...register("message", { required: true })}
        />
        <span className="form-error">
          {errors.message && `${props.t("form.errors.message")}`}
        </span>
      </div>
      <div className="btn-wrap text-center">
        <button
          type="submit"
          className="btn btn-main-color-one"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? `${props.t("common.btnsubmitting")}`
            : `${props.t("common.btnsubmit")}`}
        </button>
      </div>
    </form>
  );
}
export default ContactForm;
