import {
  faAngleUp,
  faBed,
  faBath,
  faToilet,
  faCouch,
  faBuilding,
  faCar,
  faSquare,
  faVectorSquare,
  faCheck,
  faPhone,
  faEnvelope,
  faWaterLadder,
  faFire,
  faFireBurner,
  faVideo,
  faWifi,
  faFileInvoiceDollar,
  faPenRuler,
  faCity,
  faMapLocationDot,
  faCompassDrafting,
  faBuildingUser,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(
  faAngleUp,
  faBed,
  faBath,
  faToilet,
  faCouch,
  faBuilding,
  faCar,
  faSquare,
  faVectorSquare,
  faCheck,
  faPhone,
  faEnvelope,
  faWaterLadder,
  faFire,
  faFireBurner,
  faVideo,
  faWifi,
  faFileInvoiceDollar,
  faPenRuler,
  faCity,
  faMapLocationDot,
  faCompassDrafting,
  faBuildingUser,
  faListCheck,
  faFacebook,
  faInstagram
);
