import React from "react";
import PageHeader from "./global-components/Header";
import PropertySection from "./section-components/PropertyContent";
import Footer from "./global-components/Footer";

const PropertyList = (props) => {
  return (
    <div>
      <PageHeader headertitle="propertylist" t={props.t} />
      <PropertySection items={props.items} t={props.t} propertiesImages={props.propertiesImages} />
      <Footer t={props.t} />
    </div>
  );
};

export default PropertyList;
