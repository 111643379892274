import React, { Component } from "react";
import { Link } from "react-router-dom";

class Banner extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const inlineStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/img/banner/banner_image.jpg)",
    };
    const bannerItem = this.props.items.filter(
      (item) => item.showOnMainBanner === true
    );
    return (
      <div>
        <div className="banner-area" style={inlineStyle}>
          <div className="container">
            <div className="banner-inner-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="banner-inner">
                    <div className="banner-btn-wrap">
                      {bannerItem[0].threedgallery&&<a
                        href={`${bannerItem[0].threedgallery}`}
                        className="video-play-btn mfp-iframe btn btn-main-color-one mt-2 ml-2"
                        onClick={this.props.gaEventTracker.bind(
                          this,
                          "Banner Button",
                          "Virtual 3D Tour"
                        )}
                      >
                        {this.props.t("home.banner.btntxt")}
                      </a>}
                      <Link
                        className="banner-btn btn btn-main-color-one mt-2 ml-2"
                        to={`${bannerItem[0].url}${bannerItem[0].id}`}
                        onClick={this.props.gaEventTracker.bind(
                          this,
                          "Banner Button",
                          `View Details ${bannerItem[0].title}`
                        )}
                      >
                        {this.props.t("common.btnviewdetails")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
