import React, { useState, useEffect } from "react";

import Navbar from "./components/global-components/Navbar";
import MainContent from "./components/global-components/MainContent";
import propertyDataEn from "./data/propertyListEn.json";
import propertyDataTr from "./data/propertyListTr.json";
import propertiesImages from "./data/propertiesImages.json";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "./components/utilities/FaIcons";
import BackToTopButton from "./components/global-components/BackToTopButton";

const onLanguageChange = (event) => {
  i18n.changeLanguage(event.target.value);
  onGetPropertyData(event.target.value);
  localStorage.setItem("SelectedLanguage", event.target.value);
};

const onGetPropertyData = (data) => {
  if (data === "tr") {
    return propertyDataTr;
  } else {
    return propertyDataEn;
  }
};
const getPageLanguage = () =>
  window.localStorage.SelectedLanguage || i18n.language;

function App() {
  const { t } = useTranslation();

  const [pageLanguage, setPageLanguage] = useState(getPageLanguage());
  const [propertyData, setPropertyData] = useState(
    onGetPropertyData(pageLanguage)
  );

  useEffect(() => {
    setPageLanguage(getPageLanguage());
  }, [t]);

  useEffect(() => {
    i18n.changeLanguage(pageLanguage);
  }, [pageLanguage]);

  useEffect(() => {
    setPropertyData(onGetPropertyData(pageLanguage));
  }, [pageLanguage]);

  return (
    <div className="App">
      <Navbar
        onLanguageChange={onLanguageChange}
        t={t}
        pageLanguage={pageLanguage}
      />
      <MainContent
        t={t}
        {...propertyData}
        propertiesImages={propertiesImages}
      />

      <BackToTopButton />
    </div>
  );
}

export default App;
