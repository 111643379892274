import React from "react";
import PageHeader from "./global-components/Header";
import Footer from "./global-components/Footer";
import AnalyticsEventTracker from "./utilities/AnalyticsEventTracker";
import ContactForm from "./utilities/ContactForm";

const gaEventTracker = AnalyticsEventTracker("Contact Us");

const ContactUs = (props) => {
  return (
    <div>
      <PageHeader headertitle="contactus" t={props.t} />
      <div className="contact-area pd-top-100 pd-bottom-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-page-map">
                <iframe
                  id="contact_location"
                  title="Contact Location"
                  className="w-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52154.11441645821!2d33.287010259266324!3d35.21563057630162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72af428eca13fe77!2zMzXCsDEyJzI4LjgiTiAzM8KwMTgnMzguMCJF!5e0!3m2!1str!2s!4v1654173299063!5m2!1str!2s"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <ContactForm t={props.t} />
            </div>
          </div>
          <div className="row pd-top-92">
            <div className="col-xl-3 col-sm-6">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-phone" />
                  {props.t("contactus.callus")}:
                </p>
                <a
                  href="tel:+905338848853"
                  onClick={gaEventTracker.bind(this, "Call", "Clicked")}
                >
                  <h5 className="a-info">+90 533 884 8853</h5>
                </a>
              </div>
            </div>
            {/* <div className="col-xl-3 col-sm-6">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-envelope" />
                  {props.t("contactus.havequestion")}
                </p>
                <a
                  href="mailto:tghomes22@gmail.com"
                  onClick={gaEventTracker.bind(this, "Mail", "Clicked")}
                >
                  <h5 className="a-info">tghomes22@gmail.com</h5>
                </a>
              </div>
            </div> */}
            <div className="col-xl-6 col-sm-6">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-map" />
                  {props.t("contactus.address")}
                </p>
                <a
                  href="https://goo.gl/maps/AzQ5JJn3c9joXaYu6"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={gaEventTracker.bind(this, "Location", "Clicked")}
                >
                  <h5 className="a-info">
                    {props.t("contactus.addressdefiniton")}
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer t={props.t} />
    </div>
  );
};

export default ContactUs;
