import React from "react";
import Banner from "./section-components/Banner";
import FeaturedPorject from "./section-components/FeaturedProject";
import WhyChooseUs from "./section-components/WhyChooseUs";
import Footer from "./global-components/Footer";

import AnalyticsEventTracker from "./utilities/AnalyticsEventTracker";
const gaEventTracker = AnalyticsEventTracker("Homepage");

const Home = (props) => {
  return (
    <div>
      <Banner t={props.t} items={props.items} gaEventTracker={gaEventTracker} />
      <FeaturedPorject
        items={props.items}
        t={props.t}
        gaEventTracker={gaEventTracker}
        propertiesImages={props.propertiesImages}
      />
      <WhyChooseUs t={props.t} />
      <Footer t={props.t} />
    </div>
  );
};

export default Home;
