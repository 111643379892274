import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function BackToTopButton() {
  return (
    <div className="back-to-top">
    <span className="back-top">
      <FontAwesomeIcon icon={"angle-up"} />
    </span>
  </div>
  )
}
