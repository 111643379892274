import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

class FeaturedProject extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    const featuredMainProperty = this.props.items.filter(
      (property) =>
        property.featured.value === true && property.featured.degree === "1"
    );
    const featuredSecondProperty = this.props.items.filter(
      (property) =>
        property.featured.value === true && property.featured.degree === "2"
    );
    const featuredThirdProperty = this.props.items.filter(
      (property) =>
        property.featured.value === true && property.featured.degree === "3"
    );

    const featuredMainPropertiesImages =
      this.props.propertiesImages.items.filter((item) =>
        featuredMainProperty.find((property) => property.id === item.id)
      );
    const featuredSecondPropertiesImages =
    this.props.propertiesImages.items.filter((item) =>
      featuredSecondProperty.find((property) => property.id === item.id) 
    );
      
    const featuredThirdPropertiesImages =
      this.props.propertiesImages.items.filter((item) =>
        featuredThirdProperty.find((property) => property.id === item.id)
      );

    return (
      <div className="featured-projects pd-top-60 pd-bottom-70">
        <div className="container">
          <div className="section-title">
            <h2 className="title">{this.props.t("featuredprojects.title")}</h2>
          </div>
          <div className="slider-container">
            <Swiper
              // loop={true}
              // loopfillgroupwithblank={"true"}
              spaceBetween={10}
              autoplay={{ delay: 5000 }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              modules={[Pagination, Autoplay]}
              className="featured-projects-slider"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  slidesPerGroup: 1,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                  slidesPerGroup: 2,
                },
              }}
            >
              {featuredMainProperty.length && featuredMainProperty
                .slice(0)
                .reverse()
                .map((item, i) => (
                  <div key={i} className="item">
                    <SwiperSlide
                      key={"featured-main" + i}
                      className="featured-main-slider"
                    >
                      <div className="col-12">
                        <Link
                          to={`${item.url}${item.id}`}
                          onClick={this.props.gaEventTracker.bind(
                            this,
                            "Featured Project Property Image",
                            item.title
                          )}
                        >
                          <div className="single-leading-feature">
                            <div className="slf-overlay" />
                            <div className="thumb">
                              <img
                                src={
                                  publicUrl +
                                  featuredMainPropertiesImages
                                    .slice(0)
                                    .reverse()[i].image
                                }
                                alt={imagealt}
                              />
                            </div>
                            <div className="details">
                              {item.soldout ? (
                                <span className="sold_out">
                                  {this.props.t("common.soldout")}
                                </span>
                              ) : (
                                <span className="on_sale">
                                  {this.props.t("common.onsale")}
                                </span>
                              )}
                              <div className="feature-logo">
                                <img
                                  src={
                                    publicUrl +
                                    featuredMainPropertiesImages
                                      .slice(0)
                                      .reverse()[i].icon
                                  }
                                  alt={imagealt}
                                />
                              </div>
                              <h4 className="title readeal-top">
                                {item.title}
                                {/* <Link
                                  to={`${item.url}${item.id}`}
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Project Property Title",
                                    item.title
                                  )}
                                >
                                  {item.title}
                                </Link> */}
                              </h4>
                              <h5 className="price">{item.newprice}</h5>
                              <ul className="info-list">
                                {item.features.map((features, i) => (
                                  <li key={i}>
                                    <span>
                                      <FontAwesomeIcon icon={features.icon} />{" "}
                                      {features.value}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>

                    {featuredSecondProperty.length && <SwiperSlide
                      key={"featured-second" + i}
                      className="featured-second-slider"
                    >
                      <div className="col-12">
                        <div className="single-feature">
                          <div className="thumb">
                            <Link
                              to={`${featuredSecondProperty[i].url}${featuredSecondProperty[i].id}`}
                              onClick={this.props.gaEventTracker.bind(
                                this,
                                "Featured Project Property Image",
                                featuredSecondProperty[i].title
                              )}
                            >
                              <img
                                src={
                                  publicUrl +
                                  featuredSecondPropertiesImages[i].image
                                }
                                alt={imagealt}
                              />
                            </Link>
                          </div>
                          <div className="details">
                            {featuredSecondProperty[i].soldout ? (
                              <span className="sold_out">
                                {this.props.t("common.soldout")}
                              </span>
                            ) : (
                              <span className="on_sale">
                                {this.props.t("common.onsale")}
                              </span>
                            )}
                            <div className="feature-logo">
                              <img
                                src={
                                  publicUrl +
                                  featuredSecondPropertiesImages[i].icon
                                }
                                alt={imagealt}
                              />
                            </div>
                            <h6 className="title readeal-top">
                              <Link
                                to={`${featuredSecondProperty[i].url}${featuredSecondProperty[i].id}`}
                                onClick={this.props.gaEventTracker.bind(
                                  this,
                                  "Featured Project Property Title",
                                  featuredSecondProperty[i].title
                                )}
                              >
                                {featuredSecondProperty[i].title}
                              </Link>
                            </h6>
                            <h6 className="price">
                              {featuredSecondProperty[i].newprice}
                            </h6>
                            <del>{featuredSecondProperty[i].oldprice}</del>
                            <ul className="info-list">
                              {featuredSecondProperty[i].features.map(
                                (features, i) => (
                                  <li key={i}>
                                    <span>
                                      <FontAwesomeIcon icon={features.icon} />{" "}
                                      {features.value}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                            <ul className="contact-list">
                              <li>
                                <a
                                  className="phone"
                                  href="tel:+905338848853"
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Projects Call",
                                    featuredSecondProperty[i].title
                                  )}
                                >
                                  <FontAwesomeIcon icon={"phone"} />
                                </a>
                              </li>
                              <li>
                                <a
                                  className="message"
                                  href="mailto:tghomes22@gmail.com"
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Projects Mail",
                                    featuredSecondProperty[i].title
                                  )}
                                >
                                  <FontAwesomeIcon icon={"envelope"} />
                                </a>
                              </li>
                              <li className="readeal-top">
                                <Link
                                  className="btn btn-main-color-one"
                                  to={`${featuredSecondProperty[i].url}${featuredSecondProperty[i].id}`}
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Projects View Details",
                                    featuredSecondProperty[i].title
                                  )}
                                >
                                  {this.props.t("common.btnviewdetails")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>}

                    {featuredThirdProperty.length && <SwiperSlide
                      key={"featured-third" + i}
                      className="featured-third-slider"
                    >
                      <div className="col-12">
                        <div className="single-feature">
                          <div className="thumb">
                            <Link
                              to={`${featuredThirdProperty[i].url}${featuredThirdProperty[i].id}`}
                              onClick={this.props.gaEventTracker.bind(
                                this,
                                "Featured Project Property Image",
                                featuredThirdProperty[i].title
                              )}
                            >
                              <img
                                src={
                                  publicUrl +
                                  featuredThirdPropertiesImages[i].image
                                }
                                alt={imagealt}
                              />
                            </Link>
                          </div>
                          <div className="details">
                            {featuredThirdProperty[i].soldout ? (
                              <span className="sold_out">
                                {this.props.t("common.soldout")}
                              </span>
                            ) : (
                              <span className="on_sale">
                                {this.props.t("common.onsale")}
                              </span>
                            )}
                            <div className="feature-logo">
                              <img
                                src={
                                  publicUrl +
                                  featuredThirdPropertiesImages[i].icon
                                }
                                alt={imagealt}
                              />
                            </div>
                            <h6 className="title readeal-top">
                              <Link
                                to={`${featuredThirdProperty[i].url}${featuredThirdProperty[i].id}`}
                                onClick={this.props.gaEventTracker.bind(
                                  this,
                                  "Featured Project Property Title",
                                  featuredThirdProperty[i].title
                                )}
                              >
                                {featuredThirdProperty[i].title}
                              </Link>
                            </h6>
                            <h6 className="price">
                              {featuredThirdProperty[i].newprice}
                            </h6>
                            <del>{featuredThirdProperty[i].oldprice}</del>
                            <ul className="info-list">
                              {featuredThirdProperty[i].features.map(
                                (features, i) => (
                                  <li key={i}>
                                    <span>
                                      <FontAwesomeIcon icon={features.icon} />{" "}
                                      {features.value}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                            <ul className="contact-list">
                              <li>
                                <a
                                  className="phone"
                                  href="tel:+905338848853"
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Projects Call",
                                    featuredThirdProperty[i].title
                                  )}
                                >
                                  <FontAwesomeIcon icon={"phone"} />
                                </a>
                              </li>
                              <li>
                                <a
                                  className="message"
                                  href="mailto:tghomes22@gmail.com"
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Projects Mail",
                                    featuredThirdProperty[i].title
                                  )}
                                >
                                  <FontAwesomeIcon icon={"envelope"} />
                                </a>
                              </li>
                              <li className="readeal-top">
                                <Link
                                  className="btn btn-main-color-one"
                                  to={`${featuredThirdProperty[i].url}${featuredThirdProperty[i].id}`}
                                  onClick={this.props.gaEventTracker.bind(
                                    this,
                                    "Featured Projects View Details",
                                    featuredThirdProperty[i].title
                                  )}
                                >
                                  {this.props.t("common.btnviewdetails")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>}
                  </div>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturedProject;
