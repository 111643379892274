import React from "react";
import PageHeader from "./global-components/Header";
import PropertyDetailsSection from "./section-components/PropertyDetailsContent";
import Footer from "./global-components/Footer";
import { Redirect, useParams } from "react-router-dom";
import AnalyticsEventTracker from "./utilities/AnalyticsEventTracker";
const gaEventTracker = AnalyticsEventTracker("Property Details");

const PropertyDetails = (props) => {
  const { propertyID } = useParams();
  const propertyInfo = props.items.filter((elem) => elem.id === propertyID);
  const propertyImages = props.propertiesImages.items.filter(
    (elem) => elem.id === propertyID
  );

  if (propertyInfo.length === 0) {
    return <Redirect to="/property-list" />;
  }

  return (
    <div key="propertyDetailsContainer" className="property-details-container">
      <PageHeader headertitle="propertydetails" t={props.t} />
      <PropertyDetailsSection
        {...propertyInfo[0]}
        t={props.t}
        gaEventTracker={gaEventTracker}
        {...propertyImages[0]}
      />
      <Footer t={props.t} />
    </div>
  );
};

export default PropertyDetails;
