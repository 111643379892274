import React, { Component } from "react";

class AboutUsContent extends Component {
  render() {
    return (
      <div className="about-area pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-12  align-self-center">
              <div className="section-title">
                <h5 className="sub-title">{this.props.t("about.subtitle")}</h5>
                <h2 className="title">{this.props.t("about.title")}</h2>
                <p>{this.props.t("about.content")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsContent;
