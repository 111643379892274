import React from "react";
import { useForm } from "react-hook-form";
import { Report } from "notiflix/build/notiflix-report-aio";

export default function SubscribeForm(props) {
  const {
    register,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => onPostForm(data);

  async function onPostForm(data) {
    data.property_name = props.propertyName;
    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log(result);
      Report.success(
        `${props.t("form.reports.success.title")}`,
        `${props.t("form.reports.success.message")}`,
        `${props.t("common.btnokey")}`
      );
      reset();
    } catch (err) {
      Report.failure(
        `${props.t("form.reports.failed.title")}`,
        `${props.t("form.reports.failed.message")}`,
        `${props.t("common.btnokey")}`
      );
      console.log(err);
    }
  }

  return (
    <form
      id="property_details_form"
      className="widget widget-owner-info mt-lg-0 mt-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="owner-info text-center">
        {/* <div className="details">
          <h6>December 2022</h6>
          <span className="designation">Last 1 Unit Remain</span>
        </div> */}
        <h6>{props.t("form.getupdates")}</h6>
      </div>
      <div className="contact">
        <div className="rld-single-input">
          <input
            {...register("full_name", {
              required: true,
            })}
            placeholder={props.t("contactus.name")}
            autoComplete="off"
          />
          <span className="form-error">
            {errors.full_name?.type === "required" &&
              `${props.t("form.errors.fullname")}`}
          </span>
        </div>

        <div className="rld-single-input">
          <input
            {...register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: true,
              },
            })}
            placeholder={props.t("contactus.email")}
            autoComplete="off"
          />
          <span className="form-error">
            {(errors.email?.type === "required" &&
              `${props.t("form.errors.email")}`) ||
              (errors.email?.message &&
                `${props.t("form.errors.email_format")}`)}
          </span>
        </div>

        <div className="rld-single-input">
          <input
            {...register("phone", { required: true })}
            placeholder={props.t("contactus.phone")}
            autoComplete="off"
          />
          <span className="form-error">
            {errors.phone && `${props.t("form.errors.phone")}`}
          </span>
        </div>

        <div className="btn-wrap text-center">
          <button
            type="submit"
            className="btn btn-main-color-one"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? `${props.t("common.btnsubmitting")}`
              : `${props.t("common.btnsubscribe")}`}
          </button>
        </div>
      </div>
    </form>
  );
}
