import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwiperSlider from "../utilities/SwiperSlider";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

class Services extends Component {
  render() {
    const ServicesContents = [
      <div className="item" style={{ paddingLeft: 10 }}>
        <div className="single-intro text-center">
          <div className="thumb">
            <FontAwesomeIcon icon={"map-location-dot"} />
          </div>
          <div className="details">
            <h4 className="title">{this.props.t("services.items.title1")}</h4>
          </div>
        </div>
      </div>,
      <div className="item">
        <div className="single-intro text-center">
          <div className="thumb">
            <FontAwesomeIcon icon={"list-check"} />
          </div>
          <div className="details">
            <h4 className="title">{this.props.t("services.items.title2")}</h4>
          </div>
        </div>
      </div>,
      <div className="item" style={{ paddingLeft: 10 }}>
        <div className="single-intro text-center">
          <div className="thumb">
            <FontAwesomeIcon icon={"compass-drafting"} />
          </div>
          <div className="details">
            <h4 className="title">{this.props.t("services.items.title3")}</h4>
          </div>
        </div>
      </div>,
      <div className="item" style={{ paddingLeft: 10 }}>
        <div className="single-intro text-center">
          <div className="thumb">
            <FontAwesomeIcon icon={"building-user"} />
          </div>
          <div className="details">
            <h4 className="title">{this.props.t("services.items.title4")}</h4>
          </div>
        </div>
      </div>,
    ];

    const sliderOptions = {
      className: "services-slider",
      loop: true,
      loopfillgroupwithblank: "true",
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      spaceBetween: 10,
      navigation: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
      },
      breakpoints: {
        560: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
      modules: [Autoplay, Navigation, Pagination],
    };

    return (
      <div className="service-area service-area-about mg-bottom-100 pb-xl-5 pd-0">
        <div className="container">
          <div className="section-title">
            <h5 className="sub-title">{this.props.t("services.subtitle")}</h5>
            <h2 className="title">{this.props.t("services.title")}</h2>
            <p>
              {this.props.t("services.content1")}
              <br /> {this.props.t("services.content2")}
            </p>
          </div>
          <div className="row">
            <SwiperSlider
              sliderItems={ServicesContents}
              sliderOptions={sliderOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
