import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../Home";
import PropertyList from "../PropertyList";
import PropertyDetails from "../PropertyDetails";
import AboutUs from "../AboutUs";
import Error from "../Error";
import ContactUs from "../ContactUs";

export class MainContent extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <Home {...this.props} />
        </Route>
        <Route path="/property-list">
          <PropertyList {...this.props} />
        </Route>
        <Route path="/property-details/:propertyID">
          <PropertyDetails {...this.props} />
        </Route>
        <Route path="/about-us">
          <AboutUs t={this.props.t} />
        </Route>
        <Route path="/contact-us">
          <ContactUs t={this.props.t} />
        </Route>
        <Route path="*" component={Error} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}

export default MainContent;
