import React, { Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import "./i18n";

import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import ScrollToTop from "./components/utilities/ScrollToTop";

const history = createBrowserHistory();
const TRACKING_ID = "G-YNH7WQQCMJ";
ReactGA.initialize(TRACKING_ID);
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

const preloader = (
  <div className="preloader" id="preloader">
    <div className="preloader-inner">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  </div>
);

class Root extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <Router history={history}>
        <ScrollToTop />
        <Suspense fallback={preloader}>
          <App />
        </Suspense>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("tg-homes-main"));
